import { filterBooleanTs } from "~utils/filterBooleanTs";
import { createUrlPathFromArray } from "~utils/path_utils/createUrlPathFromArray";
import { removeDefaultLanguagePrefix } from "~utils/path_utils/removeDefaultLanguagePrefix";

import type { Breadcrumb, BreadcrumbsProps } from ".";

export interface GetBreadcrumbsSchemaJsonArgs {
  breadcrumbs: BreadcrumbsProps["breadcrumbs"];
  origin: string;
}

interface BreadcrumbSchemaJson {
  "@type": "ListItem";
  position: number;
  name: string;
  item: string;
}

/**
 * ------------------------------------------------------------------------------
 * Abstraction to transform individual breadcrumb items into schema.org format.
 * ------------------------------------------------------------------------------
 */

interface TransformBreadcrumbsForSchemaJsonArgs {
  breadcrumbs: Array<Breadcrumb>;
  origin: string;
}

function transformBreadcrumbsForSchemaJson({
  breadcrumbs,
  origin,
}: TransformBreadcrumbsForSchemaJsonArgs):
  | undefined
  | Array<BreadcrumbSchemaJson> {
  return breadcrumbs.reduce(
    (accumulator: Array<BreadcrumbSchemaJson>, breadcrumb, index) => {
      const { title, link } = breadcrumb || {};
      const { story } = link || {};
      const { url } = story || {};
      const sanitisedUrl = url && removeDefaultLanguagePrefix(url);

      const urlForCrumb =
        sanitisedUrl && createUrlPathFromArray([origin, sanitisedUrl]);

      if (index > 0 && title && urlForCrumb) {
        accumulator.push({
          "@type": "ListItem",
          position: index,
          name: title,
          item: urlForCrumb,
        });
      }

      return accumulator;
    },
    []
  );
}

/**
 * ------------------------------------------------------------------------------
 * Get SEO structured data
 * Adds additional metadata for search engines.
 * Rendered as JsonLd in the body of the page.
 * https://schema.org/BlogPosting
 * ------------------------------------------------------------------------------
 */
export function getBreadcrumbsSchemaJson({
  breadcrumbs,
  origin,
}: GetBreadcrumbsSchemaJsonArgs) {
  /**
   * - If there are no breadcrumbs, or there is only one breadcrumb, then we don't need to create the schema.org breadcrumbs.
   * - This is in line with the Google documentation
   * - https://developers.google.com/search/docs/appearance/structured-data/breadcrumb#guidelines:~:text=Some%20parts%20of%20the%20URL%20path%20don%27t%20help%20people%20understand%20how%20the%20page%20fits%20in%20your%20website.%20For%20example%2C%20given%20the%20URL%20https%3A//example.com/pages/books/catcher_in_the_rye.html%2C%20the%20pages%20path%20in%20the%20URL%20doesn%27t%20add%20any%20information%2C%20and%20neither%20does%20the%20top%20level%20element%20example.com.
   */
  if (!Array.isArray(breadcrumbs)) return undefined;

  const sanitisedBreadcrumbs: Array<Breadcrumb> =
    breadcrumbs?.filter(filterBooleanTs);

  if (sanitisedBreadcrumbs.length < 2) return undefined;

  const schemaCrumbs = transformBreadcrumbsForSchemaJson({
    breadcrumbs: sanitisedBreadcrumbs,
    origin,
  });

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: schemaCrumbs,
  };
}
