import React, { useMemo } from "react";

import { faSlashForward } from "@fortawesome/sharp-regular-svg-icons/faSlashForward";
import { v4 as uuid } from "uuid";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { JsonLd } from "~components/JsonLd";
import { Link } from "~components/Link";

import { getBreadcrumbsSchemaJson } from "./_getBreadcrumbsSchemaJson";
import * as styles from "./styles.css";

import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokFieldLink } from "~types/storyblok.types";

export type Breadcrumb = {
  link?: StoryblokFieldLink;
  title: string;
};

export interface BreadcrumbsProps extends GetSprinklesArgs {
  breadcrumbs?: Array<Breadcrumb | undefined | null>;
}

export function Breadcrumbs({ breadcrumbs, ...rest }: BreadcrumbsProps) {
  const origin = "https://truelayer.com";

  const breadcrumbsSchemaJson = useMemo(() => {
    return getBreadcrumbsSchemaJson({ breadcrumbs, origin });
  }, [breadcrumbs, origin]);

  if (!Array.isArray(breadcrumbs)) return null;

  const sanitisedBreadcrumbs = breadcrumbs?.filter(Boolean);

  const { length } = sanitisedBreadcrumbs || {};
  if (length === 1) return null;

  return (
    <>
      <JsonLd data={breadcrumbsSchemaJson} />
      <Box
        display="flex"
        alignItems="center"
        color="text_lowContrast"
        gap="spacing1"
        {...rest}
      >
        {/* <IconFontAwesome
          icon={faArrowTurnUp}
          className={styles.goToParentIcon}
          flip="horizontal"
        /> */}

        {sanitisedBreadcrumbs?.map((breadcrumb, index) => {
          const isLast = index >= length - 1;

          const isFirst = index === 0;
          if (isFirst) {
            return <React.Fragment key={`fragment-${uuid()}`} />;
          }

          const hasLink = Boolean(breadcrumb?.link);

          return (
            <React.Fragment key={`fragment-${uuid()}`}>
              <Link
                link={breadcrumb?.link}
                className={styles.getResponsiveStyles({
                  isLast: length > 2 && isLast,
                  hasLink,
                })}
              >
                {breadcrumb?.title}
              </Link>

              {!isLast && (
                <IconFontAwesome
                  className={styles.getResponsiveStyles({
                    isArrow: true,
                    isLast,
                    hasLink,
                  })}
                  icon={faSlashForward}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
}
